import React from 'react';
class Rank extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data: props.data,
      sorting_index: null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.sorting_index !== state.sorting_index) {
      let tmp = props.data;
      tmp.sort((a,b)=>{return a[props.sorting_index]===b[props.sorting_index] ? 0
          :a[props.sorting_index]>=b[props.sorting_index] ? -1 : 1 ;});
      return {
        data: tmp,
        sorting_index: props.sorting_index
      };
    }
    return null;
  }

  render() {
    let rank = 1;
    let pre = null;
    return(
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th style={{width: "7%"}}>ランク</th>
            <th style={{width: "83%"}}>名前</th>
            <th style={{width: "10%"}}>{this.props.name}</th>
          </tr>
        </thead>
        <tbody >
          {
            this.state.data.map((e,i)=>{
              let name = "ばっふぁー";
              if(e[0] === this.props.channel_id || this.props.unlocked){
                let img = <img src={e[3]} alt="サムネール" className="rounded-circle img-fluid" style={{height: "20px",marginRight: "5px"}}/>;
                let name_string = e[1];
                name = <div>{img}{name_string}</div>;
              }
              if(e[this.props.sorting_index] !== pre){
                rank = i+1;
              }
              pre = e[this.props.sorting_index];
              return <tr key={i}>
                <td>{rank}</td>
                <td>{name}</td>
                <td>{e[this.props.sorting_index]}</td>
              </tr>
            })}
        </tbody>
      </table>
    );
  }
}
export default Rank;
