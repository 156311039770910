import React from 'react';
import loading_video from './bafuko_loading.mp4'

function Loading(){
    return (
      <div style={{height: "100vh", display: "flex", justifyContent: "center", alignContent: "center", backgroundColor: "#fdfdfd"}}>
        <video autoPlay={true} loop={true} muted={true}>
          <source src={loading_video}
              type="video/mp4" />
          Loading
        </video>
      </div>
    );
}

export default Loading;
