import React from 'react';
import {query_url} from './Api'
import UserInfo from './UserInfo';
import './user.css'

class User extends React.Component{
  constructor(props){
    super(props);
    if(this.props.channel_id !== null){
      const d = this.getData(this.props.channel_id);
      this.state = {
        channel_id: this.props.channel_id,
        failed: false,
        name: d[1],
        img: d[2],
        donate: d[4],
        join_cnt: d[5],
        chat_cnt: d[6],
        combo: d[7],
        user_info: this.props.user_info
      };
    }
    else{
      this.state = {
        channel_id: "",
        failed : false,
        name: "",
        donate: 0.0,
        img: "",
        join_cnt: 0,
        chat_cnt: 0,
        combo: 0,
        user_info: null
      };
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    if(this.state.user_info === null){
      this.triggerUserInfo();
    }
  }

  getData(id){
    for(const e of this.props.data){
      if(e[0] === id){
        return e;
      }
    }
    console.assert(false,this.props.channel_id);
  }

  handleChange(event){
    this.setState({channel_id: event.target.value});
  }

  triggerUserInfo(){
    fetch(query_url+'?method=get_user_info&user='+this.state.channel_id)
      .then(res => res.json())
      .then(res => {
        this.setState({user_info: res});
        this.props.callback_info(res);
      });
  }

  handleSubmit(event){
    const d = this.getData(this.state.channel_id);
    if(d === undefined){
      this.setState({failed: true});
    }
    else{
      this.setState({
        failed: false,
        name: d[1],
        img: d[2],
        donate: d[4],
        join_cnt: d[5],
        chat_cnt: d[6],
        combo: d[7],
        user_info: null
        });
      this.props.callback(this.state.channel_id);
      this.triggerUserInfo();
    }
    event.preventDefault();
  }


  render() {
    let status=null;
    let show_fail=null;
    let info=null;
    if(this.state.name !== ""){
      status = <div>
        <h1 style={{textAlign: "center"}}><img src={this.state.img} alt="サムネール" className="rounded-circle img-fluid" style={{height: "120px",marginRight: "10px"}}/>{this.state.name}</h1>
        <div className="card-deck">
          <div className="card" style={{width: "18rem", margin: "10px"}}>
            <div className="card-body">
              <h6 className="card-title" style={{textAlign: "center"}}>スパチャ</h6>
              <h1 className="card-text" style={{textAlign: "center"}}>￥<b>{this.state.donate}</b></h1>
            </div>
          </div>
          <div className="card" style={{width: "18rem", margin: "10px"}}>
            <div className="card-body">
              <h6 className="card-title" style={{textAlign: "center"}}>参加コンボ</h6>
              <h1 className="card-text" style={{textAlign: "center"}}><b>{this.state.combo}</b></h1>
            </div>
          </div>
          <div className="card" style={{width: "18rem", margin: "10px"}}>
            <div className="card-body">
              <h6 className="card-title" style={{textAlign: "center"}}>参加回数</h6>
              <h1 className="card-text" style={{textAlign: "center"}}><b>{this.state.join_cnt}</b><span style={{fontSize: "50%"}}>/{this.props.video_list.length}回</span></h1>
            </div>
          </div>
          <div className="card" style={{width: "18rem", margin: "10px"}}>
            <div className="card-body">
              <h6 className="card-title" style={{textAlign: "center"}}>チャット回数</h6>
              <h1 className="card-text" style={{textAlign: "center"}}><b>{this.state.chat_cnt}</b></h1>
            </div>
          </div>
        </div>
      </div>
    }
    if(this.state.name !== "" && this.state.user_info !== null){
      info = <div><UserInfo user_info={this.state.user_info}/></div>
    }
    if(this.state.name !== "" && this.state.user_info === null){
      info =
        <div className="d-flex justify-content-center">
          <div className="spinner-grow" role="status" style={{backgroundColor: "#43c98a",margin: "1em"}}>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
    }
    if(this.state.failed === true){
      show_fail= <div>見つかりませんでした</div>
    }
    let show_help = null;
    if(this.state.name === ""){
      show_help = <div className="d-flex justify-content-center"><a href="https://support.google.com/youtube/answer/3250431?hl=ja">どこにチャンネルIDがありますか？</a></div>
    }
    let style = {display: "flex", flexDirection: "column", justifyContent: "center", height: "calc(100vh - 112px)"};
    if(info!==null){
      style = {display: "flex", flexDirection: "column", justifyContent: "center"};
    }
    return(
      <div style={style}>
        <form onSubmit={this.handleSubmit}>
          <div className="input-group mb-3 input-group-lg align-middle" style={{margin: "1em"}}>
            <input type="text" className="form-control" value={this.state.channel_id} onChange={this.handleChange} placeholder="自分のチャンネルIDを入力してください"/>
            <div className="input-group-append">
              <button type="submit" className="btn text-white" style={{backgroundColor: "#43c98a"}}>検索</button>
            </div>
          </div>
        </form>
        {show_help}
        {show_fail}
        {status}
        {info}
      </div>
    );
  }
}
export default User;
