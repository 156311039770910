import React from 'react';

class VideoList extends React.Component{
  render() {
    return(
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>順番</th>
            <th>タイトル</th>
            <th>日時</th>
          </tr>
        </thead>
        <tbody >
          {
            this.props.video_list.map((e,i)=>{
              return <tr key={i}>
                <td>{i+1}</td>
                <td> <a style={{color: "#278b5d"}} href={"https://www.youtube.com/watch?v="+e[0]}>{e[1]}</a></td>
                <td>{e[3]}</td>
              </tr>
            })}
        </tbody>
      </table>
    );
  }
}
export default VideoList;
