import React from 'react';

class UserInfo extends React.Component{
  render() {
    return(
      <table className="table table-striped table-hover table-responsive-md">
        <thead>
          <tr>
            <th>タイトル</th>
            <th>チャット数</th>
            <th>スパチャ</th>
            <th>日時</th>
          </tr>
        </thead>
        <tbody >
          {
            this.props.user_info.map((e,i)=>{
              return <tr key={i}>
                <td> <a style={{color: "#278b5d"}} href={"https://www.youtube.com/watch?v="+e[0]}>{e[1]}</a></td>
                <td>{e[3]}</td>
                <td>{e[4]}</td>
                <td>{e[2]}</td>
              </tr>
            })}
        </tbody>
      </table>
    );
  }
}
export default UserInfo;
