import React from 'react';

class Privacy extends React.Component{
  render() {
    return(
      <div>
        <h2 id="privacypolicy">Privacy Policy</h2>

        <p>bafuko.moe takes your privacy seriously. To better protect your privacy bafuko.moe provide this privacy policy notice explaining the way your personal information is collected and used.</p>

        <h4 id="collectionofroutineinformation">Collection of Routine Information</h4>

        <p>This website track basic information about their users. This information includes, but is not limited to, IP addresses, browser details, timestamps and referring pages. None of this information can personally identify specific users to this website. The information is tracked for routine administration and maintenance purposes.</p>

        <h4 id="cookies">Cookies</h4>

        <p>Where necessary, this website uses cookies to store information about a visitor’s preferences and history in order to better serve the user and/or present the user with customized content.</p>

        <h4 id="advertisementandotherthirdparties">Advertisement and Other Third Parties</h4>

        <p>Advertising partners and other third parties may use cookies, scripts and/or web beacons to track user activities on this website in order to display advertisements and other useful information. Such tracking is done directly by the third parties through their own servers and is subject to their own privacy policies. This website has no access or control over these cookies, scripts and/or web beacons that may be used by third parties. Learn how to opt out of <a href="https://policies.google.com/technologies/cookies">Google's</a> cookie usage, and <a href="https://www.cloudflare.com/cookie-policy/">Cloudflare's</a> cookie usage.</p>

        <h4 id="linkstothirdpartywebsites">Links to Third Party Websites</h4>

        <p>I have included links on this website for your use and reference. I am not responsible for the privacy policies on these websites. You should be aware that the privacy policies of these websites may differ from my own.</p>

        <h4 id="security">Security</h4>

        <p>The security of your personal information is important to me, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While I strive to use commercially acceptable means to protect your personal information, I cannot guarantee its absolute security.</p>

        <h4 id="changestothisprivacypolicy">Changes To This Privacy Policy</h4>

        <p>This Privacy Policy is effective as of Jan 24th,2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

        <p>I reserve the right to update or change my Privacy Policy at any time and you should check this Privacy Policy periodically. If I make any material changes to this Privacy Policy, I will notify you either through the email address you have provided me, or by placing a prominent notice on my website.</p>

        <h4 id="contactinformation">Contact Information</h4>

        <p>For any questions or concerns regarding the privacy policy, please send me an email to ss1ha3tw[at]gmail.com.</p>
      </div>
    );
  }
}
export default Privacy;
