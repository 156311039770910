import React from 'react';
import User from './User'
import Loading from './Loading'
import Rank from './Rank'
import VideoList from './VideoList'
import Privacy from './Privacy'
import {query_url} from './Api'

const UNLOCKED = process.env.REACT_APP_UNLOCKED === 'true';

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      data: null,
      video_list: null,
      page: 0,
      channel_id: null,
      user_info: null
    };
    this.navbar_class_gen = this.navbar_class_gen.bind(this);
    this.set_channel_id = this.set_channel_id.bind(this);
    this.set_user_info = this.set_user_info.bind(this);
  }
  componentDidMount(){
    fetch(query_url)
      .then(res => res.json())
      .then(res => {
        this.setState({loaded: true, data: res.data, video_list: res.video_list});
      });
  }

  navbar_class_gen(self_id){
    if(self_id===this.state.page){
      return "nav-item active"
    }
    return "nav-item"
  }

  handleClick(id){
    this.setState({page: id});
  }

  set_channel_id(channel_id){
    this.setState({channel_id: channel_id});
  }

  set_user_info(user_info){
    this.setState({user_info: user_info});
  }

  render() {
    if(this.state.loaded === false){
      return (
        <>
          <Loading />
        </>
      );
    }
    let nowpage;
    switch(this.state.page){
      case 0:
        nowpage= <User data={this.state.data} video_list={this.state.video_list} callback={this.set_channel_id} callback_info={this.set_user_info} channel_id={this.state.channel_id} user_info={this.state.user_info}/>
        break;
      case 1:
        nowpage= <Rank data={this.state.data} sorting_index={5} unlocked={UNLOCKED} name="参加回数" channel_id={this.state.channel_id}/>
        break;
      case 2:
        nowpage= <Rank data={this.state.data} sorting_index={6} unlocked={UNLOCKED} name="チャット回数" channel_id={this.state.channel_id}/>
        break;
      case 3:
        nowpage= <Rank data={this.state.data} sorting_index={4} unlocked={UNLOCKED} name="金額" channel_id={this.state.channel_id}/>
        break;
      case 4:
        nowpage= <VideoList video_list={this.state.video_list} />
        break;
      case 5:
        nowpage= <Rank data={this.state.data} sorting_index={7} unlocked={UNLOCKED} name="コンボ" channel_id={this.state.channel_id}/>
        break;
      case 6:
        nowpage= <Privacy/>
        break;
      default:
        console.assert(false);
    }

    let donate_entry = null;
    if(UNLOCKED === true){
      donate_entry =
        <li className={this.navbar_class_gen(3)}>
          <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 3)}>スパチャランキング</a>
        </li>
    }

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light sticky-top" style={{backgroundColor: "#b7f8cd"}}>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className={this.navbar_class_gen(0)}>
                <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 0)}>プロフィール</a>
              </li>
              <li className={this.navbar_class_gen(5)}>
                <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 5)}>参加コンボランキング</a>
              </li>
              <li className={this.navbar_class_gen(1)}>
                <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 1)}>参加回数ランキング</a>
              </li>
              <li className={this.navbar_class_gen(2)}>
                <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 2)}>チャット回数ランキング</a>
              </li>
              {donate_entry}
              <li className={this.navbar_class_gen(4)}>
                <a className="nav-link" href="#" onClick={this.handleClick.bind(this, 4)}>配信一覧</a>
              </li>
            </ul>
          </div>
        </nav>
        <main>
          <div className="container" style={{maxWidth: "80%"}}>
            {nowpage}
          </div>
        </main>
        <footer className="mt-auto py-3">
          <ul className="navbar-nav">
            <span>
              <a href="#" onClick={this.handleClick.bind(this, 6)}>Privacy Policy</a>
            </span>
          </ul >
        </footer>
      </div>
    );
  }
}
export default App;
